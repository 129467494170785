@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply p-5;
}

p > strong {
  @apply mt-5 mb-2 block;
}
